<template>
    <div>
      <PageLoader :storage="appLoading" />
      <ServerError v-if="ServerError" />
      <v-snackbar v-model="showSnackBar" color="#FF6907" right :timeout="timeout">
        <v-layout wrap justify-center>
          <v-flex text-left class="align-self-center">
            <span style="color: #fff">{{ msg }}</span>
          </v-flex>
          <v-flex text-right>
            <v-btn small :ripple="false" text @click="showSnackBar = false">
              <v-icon style="color: #fff">mdi-close</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-snackbar>
      <v-layout wrap justify-center>
        <v-flex xs12>
          <v-layout wrap justify-start pa-5>
            <v-layout row justify-space-between align-center>
                <v-flex xs12 sm6 md6 lg6>
                  <span class="heading">Venom Details</span>
                </v-flex>
                <v-flex xs12 sm6 md6 lg6 pt-2 pt-sm-0 pt-md-0 pt-lg-0 class="text-right">
                  <v-btn
                    color="#fdac53"
                 
                     @click="adddialog = true"
                  >
                    <span
                      style="
                        font-size: 12px;
                        font-family: poppinssemibold;
                        color: black;
                      "
                    >
                      Add Venom
                      <v-icon color="black">mdi-plus</v-icon>
                    </span>
                  </v-btn>
                </v-flex>



                <v-dialog v-model="adddialog" max-width="700px">
                    <v-card rounded="lg">
                        <v-card-title>
                            <span class="heading">ADD VENOM</span>
                            <v-spacer></v-spacer>
                            <v-btn color="red" icon @click="adddialog = false">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-card-title>

                        <v-card-text>
                            <v-form >
                                <v-layout wrap justify-start pt-2>
                                    <v-flex xs12 sm12 md12 lg12 class="subheading">
                                      <span style="color:black">Name</span>
                                      <v-text-field class="pt-2 text-des" outlined v-model="name" dense hide-details>
                                      </v-text-field>
                                    </v-flex>
                                    <v-flex
                                      xs12
                                      sm12
                                      md12
                                      lg12
                                    pt-3
                                      class="subheading"
                                    >
                                      <span style="color:black">Description</span>
                                      <v-textarea 
                                        outlined
                                        v-model="desc"
                                        dense
                                        hide-details
                                      
                                        class="pt-2 text-des"
                                      >
                                      </v-textarea>
                                    </v-flex>
                                  </v-layout>
                            </v-form>
                        </v-card-text>

                     

                        <v-card-actions class="py-4 justify-end headline  lighten-2">
                            <v-btn tile
                            outlined
                            color="#FF1313"
                            light
                            :ripple="false"
                            depressed
                         
                            class="itemValue" text @click="adddialog = false"><span
                                    style="color: black;">Cancel</span> </v-btn>
                            <v-btn  tile
                            :color="appColor"
                            light
                            :ripple="false"
                            depressed
                        
                            class="itemValue" @click="itemadd()"><span style="color:white;">Save Changes</span> </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>



              </v-layout>
            <v-flex xs12 v-if="items && items.length > 0">
              <v-layout wrap justify-start pt-4>
                <v-flex xs12>
                  <v-card>
                    <div style="overflow-y: auto; overflow-x: auto;">
                    <v-simple-table>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-left tablehead">Name</th>
                              <th class="text-left tablehead">Description</th>
                              <th class="text-left tablehead action-column"><span class="pl-3">Edit</span> </th> <!-- Apply action-column class -->
                              <th class="text-left tablehead action-column">Delete</th> <!-- Apply action-column class -->
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(item, index) in items" :key="index">
                              <td  style="white-space: nowrap;" class="tableitems">{{item.name}}</td>
                              <td  style="white-space: nowrap;" class="tableitems">{{item.description}}</td>
                              <td  style="white-space: nowrap;" class="action-column">
                                

                                <v-icon
                                small
                                color="primary"
                                class="ml-4"
                                @click.stop="editSlider(item)"
                              >
                                mdi-pencil
                              </v-icon>


                              </td>
                              <td  style="white-space: nowrap;" class="action-column">
                                <v-icon
                                small
                                color="error"
                                class="ml-4"
                                @click.stop="opendeleteDialog(item)"
                              >
                                mdi-delete
                              </v-icon>

                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                      </div>
                      </v-card>
                      
                      
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 v-else>
              <v-layout
                wrap
                justify-center
                align-center
               
              >
              <v-flex xs12 class="text-center">
                  <span class="nodata">No Data Found !</span>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs12 pb-4 v-if="pages > 0">
          <v-layout wrap justify-center>
            <v-flex xs10>
              <v-pagination :length="pages" v-model="currentPage"  color="#FF6907"
              circle></v-pagination>
            </v-flex>
          </v-layout>
        </v-flex>
        
      </v-layout>

      <v-dialog v-model="editdialog" max-width="700px">
        <v-card rounded="lg">
            <v-card-title>
                <span class="heading">EDIT VENOM</span>
                <v-spacer></v-spacer>
                <v-btn color="red" icon @click="closeDialog()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>

            <v-card-text>
                <v-form >
                    <v-layout wrap justify-start pt-2>
                        <v-flex xs12 sm12 md12 lg12 class="subheading">
                          <span style="color:black">Name</span>
                          <v-text-field class="pt-2 text-des" outlined v-model="editingitem.name" dense hide-details>
                          </v-text-field>
                        </v-flex>
                        <v-flex
                          xs12
                          sm12
                          md12
                          lg12
                        pt-3
                          class="subheading"
                        >
                          <span style="color:black">Description</span>
                          <v-textarea 
                            outlined
                            v-model="editingitem.description"
                            dense
                            hide-details
                          
                            class="pt-2 text-des"
                          >
                          </v-textarea>
                        </v-flex>
                      </v-layout>
                </v-form>
            </v-card-text>

         

            <v-card-actions class="py-4 justify-end headline  lighten-2">
                <v-btn  tile
                outlined
                color="#FF1313"
                light
                :ripple="false"
                depressed
                class="itemValue"  @click="closeDialog()"><span
                        style="color: black;">Cancel</span> </v-btn>
                <v-btn  tile
                :color="appColor"
                light
                :ripple="false"
                depressed
              
                class="itemValue" @click="edit(editingitem._id)"><span style="color:white;">Save Changes</span> </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>




      <v-dialog v-model="deleteDialog" max-width="500">
        <v-card>
          <v-card-title class="heading">Confirmation</v-card-title>
          <v-card-text
            >Are you sure you want to delete this venom?</v-card-text
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              tile
              outlined
              color="#FF1313"
              light
              :ripple="false"
              depressed
              @click="deleteDialog = false"
              class="itemValue"
            >
              Cancel
            </v-btn>
            <v-btn
              tile
              :color="appColor"
              light
              :ripple="false"
              depressed
              @click="deleteoperation()"
              class="itemValue"
            >
              <span style="color: #fff">Submit</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- <v-dialog v-model="rejectDialog" max-width="500">
        <v-card>
          <v-card-title class="heading">Confirmation</v-card-title>
          <v-card-text
            >Are you sure you want to reject?</v-card-text
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              tile
              outlined
              color="#FF1313"
              light
              :ripple="false"
              depressed
              @click="rejectDialog = false"
              class="itemValue"
            >
              Cancel
            </v-btn>
            <v-btn
              tile
              :color="appColor"
              light
              :ripple="false"
              depressed
              @click="rejectoperation()"
              class="itemValue"
            >
              <span style="color: #fff">Submit</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog> -->
    </div>
  </template>
          <script>
  import axios from "axios";
  export default {
    data() {
      return {
        name:"",
        desc:"",
        appLoading: false,
        ServerError: false,
        showSnackBar: false,
        timeout: 5000,
        msg: null,
       
        keyword: "",
        page: 1,
        currentPage: 1,
        pages: 0,
        limit: 10,
        userlist: [],
        items: [],
        status:"",
        deleteDialog: false,
        deleteid:"",
        rejectDialog:false,
        rejectid:"",
        addEventListenerdialog: false,
        adddialog:false,
        editdialog:false,
        editingitem: [],
      };
    },
    beforeMount() {
      this.getData();
    },
    watch: {
      currentPage() {
        this.getData();
      },
      count() {
        this.getData();
      },
      keyword() {
        this.getData();
      },
    },
    methods: {
    
        closeDialog() {
      this.editdialog = false;
      this.getData();
    },
      getData() {
        this.appLoading = true;
        axios({
          method: "GET",
          url: "/list/venoms",
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
          params: {
          limit: this.limit,
          page: this.currentPage,
       
        },
        })
          .then((response) => {
            if (response.data.status == true) {
              {
                this.items = response.data.data;
                this.pages = Math.ceil(response.data.totalLength / this.limit);
                this.msg = response.data.msg;
                this.showSnackBar = false;
                this.appLoading = false;
              }
            } else {
              this.msg = response.data.msg;
              this.showSnackBar = true;
              this.appLoading = false;
            }
          })
          .catch((err) => {
            this.appLoading = false;
            (this.ServerError = true), console.log(err);
          });
      },
      

      itemadd() {
     
      if (!this.name) {
        this.msg = "Please Provide Name.";
        this.showSnackBar = true;
        return;
      }
      if (!this.desc) {
        this.msg = "Please Provide description.";
        this.showSnackBar = true;
        return;
      }
     
      
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/add/venom",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          name: this.name,
          desc: this.desc,
         
        },
      })
      .then((response) => {
                    this.appLoading = false;
                    if (response.data.status) {
                        this.msg = response.data.msg;
                        this.showSnackBar = true;
                        this.adddialog = false;
                     
                        this.name = null;
                        this.desc = null;
                     
                        this.getData();
                    } else {
                        this.msg = response.data.msg;
                        this.showSnackBar = true;
                    }
                })
        .catch((err) => {
          this.appLoading = false;
          (this.ServerError = true), console.log(err);
        });
    },
    editSlider(item) {
            this.editingitem = item;
            this.editdialog = true;

        },
        edit() {
            this.appLoading = true;
            var user = {};
            user["id"] = this.editingitem._id;
            user["name"] = this.editingitem.name;
            user["desc"] = this.editingitem.description;
            

          



            axios({
                url: "/edit/venom",
                method: "POST",
                data: user,
                headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
            })
                .then((response) => {
                    this.appLoading = false;
                    if (response.data.status) {
                        this.editdialog = false;
                        this.getData();
                        this.msg = response.data.msg;
                        this.showSnackBar = false;
                        
                    } else {
                        this.msg = response.data.msg;
                        this.showSnackBar = true;
                    }
                })
                .catch((err) => {
                    this.appLoading = false;
                    this.ServerError = true;
                    console.log(err);
                });
        },
        opendeleteDialog(item) {
        this.deleteid = item._id;
        this.deleteDialog = true;
      },
      deleteoperation() {
        this.appLoading = true;
        axios({
          method: "POST",
          url: "/delete/venom",
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
          data: {
            id: this.deleteid,
          },
        })
        .then((response) => {
                    this.deleteDialog= false;
                    this.appLoading = false;
                    if (response.data.status) {
                        this.msg = response.data.msg;
                        this.showSnackBar = true;
                        this.getData();
                    } else {
                        this.msg = response.data.msg;
            this.showSnackBar = true;
                    }
                })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });
      },
    //   rejectoperation() {
    //     this.appLoading = true;
    //     axios({
    //       method: "POST",
    //       url: "/operation/removedeleterequest",
    //       headers: {
    //         "x-auth-token": localStorage.getItem("token"),
    //       },
    //       data: {
    //         id: this.rejectid,
    //       },
    //     })
    //       .then((response) => {
    //         if (response.data.status == true) {
    //           {
    //             this.msg = "Rejected Successfully.";
    //             this.showSnackBar = true;
    //             this.appLoading = false;
    //             this.rejectDialog=false;
    //           }
    //         } else {
    //           this.msg = response.data.msg;
    //           this.showSnackBar = true;
    //           this.appLoading = false;
    //           this.rejectDialog=false;

    //         }
    //       })
    //       .catch((err) => {
    //         this.appLoading = false;
    //         this.ServerError = true;
    //         console.log(err);
    //       });
    //   },
    },
  };
  </script>
          <style>
  .heading {
    font-family: poppinsregular;
    font-size: 20px;
    font-weight: 900;
  }
  .subheading {
    font-family: poppinsregular;
    font-size: 15px;
    font-weight: 500;
  }
  </style>